export const data: any = {
  personType: ["Física", "Jurídica"],
  risk: [
    "Nivel Bajo",
    "Nivel Medio",
    "Nivel Alto",
    " Nivel Alto (PEPS o Vinculado PEPS)",
  ],
  fileForm: ["Físico", "Digital"],
  typeAccount: ["Mancomunada", "Individual", "Parval Simple"],
  userRole: [
    { id: 9, role_name: "Ejecutivo" },
    { id: 10, role_name: "Cumplimiento" },
    { id: 11, role_name: "Operaciones" },
    { id: 12, role_name: "Servicio al Cliente" },
    { id: 13, role_name: "Administrador" },
  ],
  documents: [
    { id: 1, name: "Estado de cuenta" },
    { id: 2, name: "Historial laboral" },
    { id: 3, name: "Contrato corretaje digital" },
    { id: 4, name: "Formulario cononzca su cliente (I)" },
    { id: 5, name: "Contrato CEVALDOM (Anexo II)" },
    { id: 6, name: "Check list" },
    { id: 7, name: "Documentos de identidad" },
    { id: 8, name: "Verificación Biométrica" },
  ],
  ext: [
    "image/png",
    "image/jpeg",
    "image/jpg",
    ".pdf",
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".eml",
  ],
};
