import axios from "axios";
import { URL } from "../../utils/UrlConstants";
import { publicFetch } from "../../utils/CustomFetch";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls";
import { processPaymentType } from "../../types/ProcessPaymentTilopay";

export const loginTilopay = async () => {
  let responseData: any = {};
  const data = {
    apiuser: "pE1fDw",
    password: "FGZt74",
  };
  await privateFetch
    .post(URL.REQUEST_TILOPAY_LOGIN, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const processPaymentTilopay = async (
  data: processPaymentType,
  token: string
) => {
  let responseData: any = {};

  await axios
    .post(URL.REQUEST_TILOPAY_PROCESSPAYMENT, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
        Authorization: `Bearer ${token}`,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};
