import { useState, useEffect, ChangeEvent } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
  Stack,
  Dialog,
  Tooltip,
  Typography,
  useTheme,
  Box,
  Card,
  CardContent,
  styled,
} from "@mui/material";
import FancyPaper from "../components/FancyPaper";
import { useApp } from "../hooks/useApp";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: "4px",
  transition: "transform 0.3s ease-in-out",
  backgroundColor: "#f5f5f5",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: theme.shadows[10],
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "auto",
  padding: `${theme.spacing(1.5)}!important`,
  fontWeight: 600,
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const FeatureItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const UpgradeMembershipBooking = () => {
  const { t } = useTranslation();
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    isAdmin,
    handleDataCompanies,
    company,
  } = useApp();
  const theme = useTheme();

  const plans = [
    {
      id: "1",
      name: "Básico",
      price: "45",
      description: "$450 anual gratis 2 meses",
      features: [
        `50 ${t("reservationsPerMonth")}`,
        `${t("customizableBookingPage")}`,
        `${t("managementAndControlOfReservations")}`,
        `${t("parameterizableSystem")}`,
        `${t("unlimitedUsersAndAssociates")}`,
        `${t("accessByProfiles")}`,
        `${t("configurationOfCustomizable")}`,
        `${t("onlineSupport")}`,
        `${t("multilanguageSystem")}`,
      ],
      popular: false,
    },
    {
      id: "2",
      name: "Medio",
      price: "80",
      description: "$800 anual gratis 2 meses",
      features: [
        `100 ${t("reservationsPerMonth")}`,
        `${t("customizableBookingPage")}`,
        `${t("managementAndControlOfReservations")}`,
        `${t("parameterizableSystem")}`,
        `${t("unlimitedUsersAndAssociates")}`,
        `${t("accessByProfiles")}`,
        `${t("configurationOfCustomizable")}`,
        `${t("onlineSupport")}`,
        `${t("multilanguageSystem")}`,
      ],
      popular: false,
    },
    {
      id: "3",
      name: "Plus",
      price: "180",
      description: "$1800 anual gratis 2 meses",
      features: [
        `${t("UnlimitedReservationsPerMonth")}`,
        `${t("customizableBookingPage")}`,
        `${t("managementAndControlOfReservations")}`,
        `${t("parameterizableSystem")}`,
        `${t("unlimitedUsersAndAssociates")}`,
        `${t("accessByProfiles")}`,
        `${t("configurationOfCustomizable")}`,
        `${t("onlineSupport")}`,
        `${t("multilanguageSystem")}`,
      ],
      popular: true,
    },
    {
      id: "4",
      name: "Residencial Personalizado",
      price: "99.99",
      description: "All you need for larger organizations.",
      features: [
        `${t("UnlimitedReservationsPerMonth")}`,
        `${t("customizableBookingPage")}`,
        `${t("managementAndControlOfReservations")}`,
        `${t("parameterizableSystem")}`,
        `${t("unlimitedUsersAndAssociates")}`,
        `${t("accessByProfiles")}`,
        `${t("configurationOfCustomizable")}`,
        `${t("onlineSupport")}`,
        `${t("multilanguageSystem")}`,
        `${t("messageManagement")}`,
        `${t("guestManagement")}`,
        `${t("houseOrAppartmentManagement")}`,
        `${t("financeManagement")}`,
      ],
      popular: false,
    },
  ];

  return (
    <>
      <FancyPaper pagetitle={`${t("choosePlan")}`}>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <StyledCard elevation={plan.popular ? 8 : 2}>
                <CardContent
                  sx={{
                    p: 6,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {plan.popular && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        backgroundColor: "primary.main",
                        color: "white",
                        py: 0.5,
                        px: 2,
                        borderRadius: 1,
                        alignSelf: "flex-start",
                        mb: 2,
                      }}
                    >
                      {t("mostPopular")}
                    </Typography>
                  )}
                  <Typography
                    variant="h5"
                    component="h2"
                    fontWeight="bold"
                    gutterBottom
                  >
                    {plan.name}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="p"
                    fontWeight="bold"
                    sx={{ mb: 2 }}
                  >
                    ${plan.price}
                    <Typography
                      variant="subtitle1"
                      component="span"
                      color="text.secondary"
                    >
                      /{t("month")}
                    </Typography>
                  </Typography>
                  <Typography color="text.secondary" sx={{ mb: 3 }}>
                    {plan.description}
                  </Typography>

                  <Box sx={{ mb: 3 }}>
                    {plan.features.map((feature, idx) => (
                      <FeatureItem key={idx}>
                        <CheckIcon sx={{ color: "#2196f3" }} />
                        <Typography>{feature}</Typography>
                      </FeatureItem>
                    ))}
                  </Box>
                  <Link to={"/dashboard/checkout/" + plan.id}>
                    <StyledButton
                      variant={plan.popular ? "contained" : "outlined"}
                      color="primary"
                      fullWidth
                      aria-label={`Choose ${plan.name}`}
                    >
                      {t("choosePlan")}
                    </StyledButton>
                  </Link>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </FancyPaper>
    </>
  );
};

export default UpgradeMembershipBooking;
