import axios from "axios";
import { getItem } from "./LocalStorageManager";
import jwt_decode from "jwt-decode";
import UrlBase from "../url/Urls";

const publicFetch = axios.create({
  baseURL: UrlBase.base_url + UrlBase.api_context,
});

const privateFetch = axios.create({
  baseURL: UrlBase.base_url + UrlBase.api_context,
});

privateFetch.interceptors.request.use(
  async (config) => {
    //validate uri to send token
    const { origin } = new URL(config.baseURL + config.url);
    const allowedOrigins = [UrlBase.base_url];
    const authInfo = await getItem("authSpaceAdmin");
    const decodeToken = jwt_decode(authInfo?.tokenInfo?.access_token);
    const expiresAt = decodeToken.exp;
    let valid = new Date().getTime() / 1000 < expiresAt;
    //OJO HAY QUE DESCOMENTAR ESTO LUEGO
    if (allowedOrigins.includes(origin) && valid) {
      config.headers.Authorization = `Bearer ${authInfo?.tokenInfo?.access_token}`;
    }
    return config;
  }
  // error=>{
  //   return Promise.reject(error);
  // }
);

export { publicFetch, privateFetch };
